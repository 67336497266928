import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "58",
  height: "16",
  viewBox: "0 0 58 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "clip-path": "url(#ldkbieqgna)" }, [
      _createElementVNode("path", {
        class: "fill-yahoo_logo dark:fill-radio_group_text_color_dark",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M11.39 3.961H8.011L6.031 8.69 4.051 3.96H.67l3.683 8.792L2.994 16h3.379c1.69-4.034 3.339-8.03 5.017-12.039zM54.36.025h3.636L54.707 7.99H51.07L54.36.025zM51.964 8.76c1.132 0 2.05.92 2.05 2.055a2.053 2.053 0 0 1-2.05 2.055c-1.132 0-2.05-.92-2.05-2.055 0-1.135.918-2.055 2.05-2.055zm-7.15-5.037c2.546 0 4.61 2.07 4.61 4.622a4.616 4.616 0 0 1-4.61 4.621 4.616 4.616 0 0 1-4.611-4.621 4.616 4.616 0 0 1 4.61-4.622zm0 2.817c.994 0 1.8.808 1.8 1.805 0 .996-.806 1.804-1.8 1.804-.995 0-1.8-.808-1.8-1.804 0-.997.805-1.805 1.8-1.805zm-9.899-2.817c2.547 0 4.611 2.07 4.611 4.622a4.616 4.616 0 0 1-4.61 4.621 4.616 4.616 0 0 1-4.611-4.621 4.616 4.616 0 0 1 4.61-4.622zm0 2.817c.995 0 1.8.808 1.8 1.805 0 .996-.805 1.804-1.8 1.804-.994 0-1.8-.808-1.8-1.804 0-.997.806-1.805 1.8-1.805zM21.304 0h3.048v4.751s.534-1.029 2.296-1.029c1.762 0 2.892.777 2.915 3.96v5.187h-3.05V7.681c0-.598-.483-1.083-1.08-1.083-.61 0-1.095.503-1.08 1.114v5.157h-3.05V0zm-4.152 3.961h3.049v8.908h-3.049v-.84c-.683.588-1.534.937-2.456.937-2.249 0-4.072-2.07-4.072-4.622 0-2.553 1.823-4.622 4.072-4.622.922 0 1.773.349 2.456.936v-.697zM15.435 6.54c.994 0 1.8.808 1.8 1.805 0 .996-.806 1.804-1.8 1.804-.995 0-1.8-.808-1.8-1.804 0-.997.806-1.805 1.8-1.805z"
      })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "ldkbieqgna" }, [
        _createElementVNode("path", {
          fill: "#fff",
          transform: "translate(.667)",
          d: "M0 0h57.333v16H0z"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }